body {
  background: #b5c3c4;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-family: "Caveat", cursive;
}
  #container {
    max-width: 770px;
    min-width: 220px;
    margin: 0 auto;
    padding: 200px 10px 0px;
  }
    h1 {
      font-size: 4em;
    }
    h2 {
      font-size: 3em;
    }
    #form_container {
      padding-top: 100px;
    }
